<template>
  <div>
    <vs-card>
      <h4 class="mb-2 ml-5 mt-2">Please fill the user details</h4>
      <vs-form autocomplete="off">
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('firstName') && nurseData.firstName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('firstName') ? true : false"
                v-validate="'required|max:150'"
                name="firstName"
                data-vv-as="first name"
                label="First Name"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('firstName')"
                v-model="nurseData.firstName"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('lastName') && nurseData.lastName != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('lastName') ? true : false"
                v-validate="'required|max:150'"
                name="lastName"
                data-vv-as="last name"
                label="Last Name"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('lastName')"
                v-model="nurseData.lastName"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('preferredName') &&
                  nurseData.preferredName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('preferredName') ? true : false"
                v-validate="'max:150'"
                name="preferredName"
                data-vv-as="preferred name"
                label="Preferred Name (Optional)"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('preferredName')"
                v-model="nurseData.preferredName"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('email') && nurseData.email != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('email') ? true : false"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
                label="Email"
                icon-no-border
                icon="icon icon-mail"
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('email')"
                v-model="nurseData.email"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label>Date Of Birth</label>
              <datepicker
                format="dd/MM/yyyy"
                v-model="nurseData.dateOfBirth"
                :input-class="{
                  'is-true':
                    !errors.has('dateOfBirth') && nurseData.dateOfBirth,
                  'is-danger': errors.has('dateOfBirth'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="nurseData.dateOfBirth"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('dateOfBirth')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger" style="font-size: 0.75em">
                {{ errors.first("dateOfBirth") }}
              </span>
              <input
                type="hidden"
                data-vv-as="Date of birth"
                data-vv-validate-on="change"
                name="dateOfBirth"
                v-validate="{ required: isRequired }"
                v-model="nurseData.dateOfBirth"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('contactNumber') &&
                  nurseData.contactNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('contactNumber') ? true : false"
                v-validate="'required'"
                name="contactNumber"
                :danger-text="errors.first('contactNumber')"
                data-vv-as="contact number"
                label="Contact Number"
                icon-no-border
                icon="icon icon-phone"
                icon-pack="feather"
                class="w-full"
                v-model="nurseData.contactNumber"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('password') && nurseData.password != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('password') ? true : false"
                v-validate="{
                  required: true,
                  min: 8,
                  regex:
                    /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
                }"
                name="password"
                data-vv-as="password"
                label="Password"
                icon-no-border
                icon-pack="feather"
                class="w-full"
                :danger-text="errors.first('password')"
                autocomplete="new-password"
                v-model="nurseData.password"
                :icon="passwordIcon"
                :type="passwordType"
                @click.native="showHidePassword($event)"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('ahpraRegistrationNumber') &&
                  nurseData.ahpraRegistrationNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('ahpraRegistrationNumber') ? true : false"
                v-validate="'required'"
                name="ahpraRegistrationNumber"
                :danger-text="errors.first('ahpraRegistrationNumber')"
                data-vv-as="AHPRA Registration Number"
                label="AHPRA Registration Number"
                icon-no-border
                icon="icon icon-briefcase"
                icon-pack="feather"
                class="w-full"
                v-model="nurseData.ahpraRegistrationNumber"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label class="vs-input--label">AHPRA Expiry date</label>
              <datepicker
                format="dd/MM/yyyy"
                :disabled-dates="disabledDates"
                v-model="nurseData.ahpraExiryDate"
                :input-class="{
                  'is-true':
                    !errors.has('ahpraExiryDate') && nurseData.ahpraExiryDate,
                  'is-danger': errors.has('ahpraExiryDate'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="nurseData.ahpraExiryDate"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('ahpraExiryDate')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger" style="font-size: 0.75em">{{
                errors.first("ahpraExiryDate")
              }}</span>
              <input
                type="hidden"
                data-vv-as="APHRA expiry date"
                data-vv-validate-on="change"
                name="ahpraExiryDate"
                v-validate="'required'"
                v-model="nurseData.ahpraExiryDate"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('indemnityInsuranceProvider') &&
                  nurseData.indemnityInsuranceProvider != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('indemnityInsuranceProvider') ? true : false
                "
                v-validate="'required'"
                :danger-text="errors.first('indemnityInsuranceProvider')"
                name="indemnityInsuranceProvider"
                data-vv-as="indeminity insurance provider"
                label="Indemnity Insurance Provider"
                class="w-full"
                icon-no-border
                icon="icon icon-file"
                icon-pack="feather"
                v-model="nurseData.indemnityInsuranceProvider"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('indemnityInsuranceNumber') &&
                  nurseData.indemnityInsuranceNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('indemnityInsuranceNumber') ? true : false
                "
                v-validate="'required'"
                name="indemnityInsuranceNumber"
                :danger-text="errors.first('indemnityInsuranceNumber')"
                data-vv-as="indemnity insurance number"
                label="Indemnity Insurance number"
                class="w-full"
                icon-no-border
                icon="icon icon-file"
                icon-pack="feather"
                v-model="nurseData.indemnityInsuranceNumber"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5 custom-calendar">
              <label class="vs-input--label">Indemnity Insurance Expirys</label>
              <datepicker
                format="dd/MM/yyyy"
                :disabled-dates="disabledDates"
                v-model="nurseData.indemnityInsuranceExpiry"
                :input-class="{
                  'is-true':
                    !errors.first('indemnityInsuranceExpiry') &&
                    nurseData.indemnityInsuranceExpiry,
                  'is-danger': errors.first('indemnityInsuranceExpiry'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="nurseData.indemnityInsuranceExpiry"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('indemnityInsuranceExpiry')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger" style="font-size: 0.75em">{{
                errors.first("indemnityInsuranceExpiry")
              }}</span>
              <input
                type="hidden"
                data-vv-as="Indemnity Insurance Expiry"
                data-vv-validate-on="change"
                name="indemnityInsuranceExpiry"
                v-validate="'required'"
                v-model="nurseData.indemnityInsuranceExpiry"
              />
            </div>
          </vs-col>
          <!--<vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div  class="w-full m-5">
              <label class="vs-input&#45;&#45;label">Postal Address</label>
              <v-select
                v-model="nurseData.postalAddress"
                class="style-chooser"
                :class="{'six-pixel': errors.has('selectPostal')}"
                @search="searchSuburb"
                :options="items"
                :reduce="displayAddress => displayAddress.displayAddress"
                label="displayAddress"

                :danger="errors.first('selectPostal')"
                :success="!errors.first('selectPostal') && nurseData.postalAddress!=''"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required'"
                name="selectPostal"
                data-vv-as="Postal Address"
                placeholder="Select Postal Address">
              </v-select>
              <span class="text-danger" style="font-size: 0.75em">{{ errors.first('selectPostal') }}</span>
            </div>
          </vs-col>-->
          <vs-col
            id="aphraUpload"
            class="vs-con-loading__container"
            vs-type="flex"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label>Attach AHPRA Certificate</label>
              <div
                :class="{ dateClass: !errors.has('ahpraCertificate') }"
                class=""
              >
                <div class="mb-4" v-if="nurseData.ahpraCertificate">
                  <div
                    v-if="
                      determineFileType(nurseData.ahpraCertificate) === 'pdf'
                    "
                    style="margin: auto; width: 50%"
                  >
                    <a :href="nurseData.ahpraCertificate" target="_blank"
                      >Click here to view file</a
                    >
                  </div>
                  <div
                    v-else
                    style="margin: auto"
                    class="large-square-img-wrapper"
                  >
                    <img :src="nurseData.ahpraCertificate" class="img-fluid" />
                  </div>
                </div>
                <div
                  :class="{ 'mt-3': nurseData.ahpraCertificate }"
                  style="text-align: center; position: relative"
                >
                  <label
                    id="ahpraId"
                    :class="{ 'six-pixel': errors.has('aphraCertificate') }"
                    class="image-upload"
                    style="
                      border: 1px solid #cccccc;
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                      padding: 10px;
                    "
                  >
                    + Attach Document
                    <input
                      type="file"
                      @change="uploadImage($event, 'aphraUpload')"
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
              </div>
              <span class="text-danger" style="font-size: 0.75em">{{
                errors.first("ahpraCertificate")
              }}</span>
            </div>
          </vs-col>

          <vs-col
            id="insuranceUpload"
            class="vs-con-loading__container"
            vs-type="flex"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label>Attach Insurance Certificate</label>
              <div
                :class="{ dateClass: !errors.has('insuranceCertificate') }"
                class=""
              >
                <div class="mb-4" v-if="nurseData.insuranceCertificate">
                  <div
                    v-if="
                      determineFileType(nurseData.insuranceCertificate) ===
                      'pdf'
                    "
                    style="margin: auto; width: 50%"
                  >
                    <a :href="nurseData.insuranceCertificate" target="_blank"
                      >Click here to view file</a
                    >
                  </div>
                  <div
                    v-else
                    style="margin: auto"
                    class="large-square-img-wrapper"
                  >
                    <img
                      :src="nurseData.insuranceCertificate"
                      class="img-fluid"
                    />
                  </div>
                </div>
                <div
                  :class="{ 'm-3': nurseData.insuranceCertificate }"
                  style="text-align: center; position: relative"
                >
                  <label
                    id="insuranceId"
                    :class="{ 'six-pixel': errors.has('insuranceCertificate') }"
                    class="image-upload"
                    style="
                      border: 1px solid #cccccc;
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                      padding: 10px;
                    "
                  >
                    + Attach Document
                    <input
                      type="file"
                      @change="uploadImage($event, 'insuranceUpload')"
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
              </div>
              <span class="text-danger" style="font-size: 0.75em">{{
                errors.first("insuranceCertificate")
              }}</span>
            </div>
          </vs-col>

          <vs-col
            id="profileImageUpload"
            class="vs-con-loading__container"
            vs-type="flex"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label>Upload Profile Picture (Optional)</label>
              <div class="">
                <div
                  class="mb-4 large-img-wrapper"
                  v-if="nurseData.profileImage"
                  style="margin: auto"
                >
                  <img :src="nurseData.profileImage" class="img-fluid" alt />
                  <!-- <vs-avatar
                    size="130px"
                    align="center"
                    :src="nurseData.profileImage"
                  />-->
                </div>
                <div style="text-align: center; position: relative">
                  <label
                    class="image-upload"
                    style="
                      border: 1px solid #cccccc;
                      display: inline-block;
                      width: 100%;
                      border-radius: 5px;
                      padding: 10px;
                    "
                  >
                    Upload a profile photo
                    <input
                      type="file"
                      accept="image/*"
                      @change="uploadImage($event, 'profileImageUpload')"
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
              </div>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('instagram') ? true : false"
                :success="
                  !errors.first('instagram') && nurseData.instagram != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate
                data-vv-validate-on="blur"
                data-vv-as="instagram"
                name="instagram"
                label-placeholder="instagram"
                placeholder="Instagram (Optional)"
                v-model="nurseData.instagram"
                class="w-full mt-6"
              />
              <span class="text-danger text-sm">{{
                errors.first("instagram")
              }}</span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('facebook') ? true : false"
                :success="!errors.first('facebook') && nurseData.facebook != ''"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate
                data-vv-validate-on="blur"
                data-vv-as="facebook"
                name="facebook"
                label-placeholder="facebook"
                placeholder="Facebook (Optional)"
                v-model="nurseData.facebook"
                class="w-full mt-6"
              />
              <span class="text-danger text-sm">{{
                errors.first("facebook")
              }}</span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('website') ? true : false"
                :success="!errors.first('website') && nurseData.website != ''"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate
                data-vv-validate-on="blur"
                data-vv-as="website"
                name="website"
                label-placeholder="website"
                placeholder="Website (Optional)"
                v-model="nurseData.website"
                class="w-full mt-6"
              />
              <span class="text-danger text-sm">{{
                errors.first("website")
              }}</span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('clinicName') ? true : false"
                :success="
                  !errors.first('clinicName') && nurseData.clinicName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="{ required: isRequired }"
                data-vv-validate-on="blur"
                data-vv-as="clinic name"
                name="clinicName"
                label-placeholder="Clinic Name"
                placeholder="Clinic Name"
                v-model="nurseData.clinicName"
                class="w-full mt-6"
              />
              <span class="text-danger text-sm">
                {{ errors.first("clinicName") }}
              </span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('clinicAddress1') ? true : false"
                :success="
                  !errors.first('clinicAddress1') &&
                  nurseData.clinicAddress1 != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate
                data-vv-validate-on="blur"
                data-vv-as="Clinic Address 1"
                name="clinicAddress1"
                label-placeholder="Clinic Address 1"
                placeholder="Clinic Address 1 (Optional)"
                v-model="nurseData.clinicAddress1"
                class="w-full mt-6"
              />
              <span class="text-danger text-sm">{{
                errors.first("clinicAddress1")
              }}</span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('city') ? true : false"
                :success="!errors.first('city') && nurseData.city != ''"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="{ required: isRequired }"
                data-vv-validate-on="blur"
                data-vv-as="city"
                label-placeholder="City"
                name="city"
                placeholder="City"
                v-model="nurseData.city"
                class="w-full mt-6"
              />
              <span class="text-danger text-sm">
                {{ errors.first("city") }}
              </span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <vs-input
                :danger="errors.first('stateOrRegion') ? true : false"
                :success="
                  !errors.first('stateOrRegion') &&
                  nurseData.stateOrRegion != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="{ required: isRequired }"
                data-vv-validate-on="blur"
                data-vv-as="state or region"
                label-placeholder="State/Region"
                name="stateOrRegion"
                placeholder="State/Region"
                v-model="nurseData.stateOrRegion"
                class="w-full mt-6"
              />
              <span class="text-danger text-sm">
                {{ errors.first("stateOrRegion") }}
              </span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
            id="photoIdUpload"
            class="vs-con-loading__container"
          >
            <div class="w-full m-5">
              <div :class="{ dateClass: !errors.has('photoId') }" style="">
                <label class="vs-input--label custom-label"
                  >Attach Photo ID such as a drivers' licence or passport
                  (Optional)</label
                >
                <div style="margin-top: 10px; margin-bottom: 10px">
                  <div class="mb-4" v-if="nurseData.photoId">
                    <div
                      v-if="determineFileType(nurseData.photoId) === 'pdf'"
                      style="margin: auto; width: 50%"
                    >
                      <a :href="nurseData.photoId" target="_blank"
                        >Click here to view file</a
                      >
                    </div>
                    <div
                      v-else
                      style="margin: auto"
                      class="register-img-wrapper"
                    >
                      <img :src="nurseData.photoId" class="img-fluid" />
                    </div>
                  </div>
                  <div
                    :class="{ 'mt-3': nurseData.photoId }"
                    style="text-align: center; position: relative"
                  >
                    <label
                      id="photoId"
                      :class="{ 'six-pixel': errors.has('photoId') }"
                      class="image-upload photo-id-upload"
                      style="
                        border: 1px solid #cccccc;
                        display: inline-block;
                        width: 100%;
                        border-radius: 5px;
                        padding: 10px;
                      "
                    >
                      + Attach Document
                      <input
                        type="file"
                        @change="uploadImage($event, 'photoIdUpload')"
                        id="file-input"
                        hidden
                      />
                    </label>
                  </div>
                </div>
              </div>
              <span class="text-danger text-sm">
                {{ errors.first("photoId") }}
              </span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
                >Have you ever had any restrictions imposed on your ability to
                practice?</label
              >
              <vs-radio
                vs-value="Yes"
                vs-name="radios1"
                style="margin-right: 20px"
                v-model="nurseData.restrictionImposed"
                @change="validationKeys.showRestrictionImposeError = false"
                >Yes</vs-radio
              >
              <vs-radio
                vs-value="No"
                vs-name="radios1"
                v-model="nurseData.restrictionImposed"
                @change="validationKeys.showRestrictionImposeError = false"
                >No</vs-radio
              >
              <span
                v-if="validationKeys.showRestrictionImposeError"
                style="display: block"
                class="text-danger text-sm"
                >Please select one option.</span
              >
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
                >Change in registration (Do you agree to tell us within 48hrs of
                any change in your registration status?)</label
              >
              <vs-radio
                vs-value="Yes"
                vs-name="radios2"
                style="margin-right: 20px"
                v-model="nurseData.aggreementOnAhpraRegistrationChanged"
                @change="
                  validationKeys.showAggreementOnAhpraRegistrationChangedError = false
                "
                >Yes</vs-radio
              >
              <vs-radio
                vs-value="No"
                vs-name="radios2"
                v-model="nurseData.aggreementOnAhpraRegistrationChanged"
                @change="
                  validationKeys.showAggreementOnAhpraRegistrationChangedError = false
                "
                >No</vs-radio
              >
              <span
                v-if="
                  validationKeys.showAggreementOnAhpraRegistrationChangedError
                "
                style="display: block"
                class="text-danger text-sm"
                >Please select one option.</span
              >
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
                >Change in insurance (Do you agree to tell us within 48hrs of
                any change in your insurance?)</label
              >
              <vs-radio
                vs-value="Yes"
                vs-name="radios3"
                style="margin-right: 20px"
                v-model="nurseData.aggreementOnIndemnityRegistrationChanged"
                @change="
                  validationKeys.showAggreementOnIndemnityRegistrationChangedError = false
                "
                >Yes</vs-radio
              >
              <vs-radio
                vs-value="No"
                vs-name="radios3"
                v-model="nurseData.aggreementOnIndemnityRegistrationChanged"
                @change="
                  validationKeys.showAggreementOnIndemnityRegistrationChangedError = false
                "
                >No</vs-radio
              >
              <span
                v-if="
                  validationKeys.showAggreementOnIndemnityRegistrationChangedError
                "
                style="display: block"
                class="text-danger text-sm"
                >Please select one option.</span
              >
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
                >Are you an RN or an EN (If an EN, you must read the guidelines
                on supervision and acknowledge the requirements involved.
                Contact us for more detail if unsure.)</label
              >
              <vs-radio
                vs-value="RN"
                vs-name="radios4"
                style="margin-right: 20px"
                v-model="nurseData.rnOrEn"
                @change="validationKeys.showRnOrEnError = false"
                >RN</vs-radio
              >
              <vs-radio
                vs-value="EN"
                vs-name="radios4"
                v-model="nurseData.rnOrEn"
                @change="validationKeys.showRnOrEnError = false"
                >EN</vs-radio
              >
              <span
                v-if="validationKeys.showRnOrEnError"
                style="display: block"
                class="text-danger text-sm"
                >Please select one option.</span
              >
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
                >How many years of experience have you had as an RN or an
                EN?</label
              >
              <vs-input
                :danger="errors.first('expericedYearInRnOrEn') ? true : false"
                :success="
                  !errors.first('expericedYearInRnOrEn') &&
                  nurseData.expericedYearInRnOrEn != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="{ required: isRequired, decimal: 2 }"
                data-vv-validate-on="blur"
                data-vv-as="year of experience in RN or EN"
                label-placeholder=""
                name="expericedYearInRnOrEn"
                placeholder=""
                v-model="nurseData.expericedYearInRnOrEn"
                class="w-full"
              />
              <span class="text-danger text-sm">
                {{ errors.first("expericedYearInRnOrEn") }}
              </span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
                >In what areas/fields?</label
              >
              <vs-input
                :danger="errors.first('areasForRnOrEn') ? true : false"
                :success="
                  !errors.first('areasForRnOrEn') &&
                  nurseData.areasForRnOrEn != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="{ required: isRequired }"
                data-vv-validate-on="blur"
                data-vv-as="area of experience"
                label-placeholder=""
                name="areasForRnOrEn"
                placeholder=""
                v-model="nurseData.areasForRnOrEn"
                class="w-full"
              />
              <span class="text-danger text-sm">
                {{ errors.first("areasForRnOrEn") }}
              </span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
                >For how many years have you been a cosmetic injector?</label
              >
              <vs-input
                :danger="
                  errors.first('cosmeticInjectorExperienceYears') ? true : false
                "
                :success="
                  !errors.first('cosmeticInjectorExperienceYears') &&
                  nurseData.cosmeticInjectorExperienceYears != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="{ required: isRequired, decimal: 2 }"
                data-vv-validate-on="blur"
                data-vv-as="cosmetic injector experience year"
                label-placeholder=""
                name="cosmeticInjectorExperienceYears"
                placeholder=""
                v-model="nurseData.cosmeticInjectorExperienceYears"
                class="w-full"
              />
              <span class="text-danger text-sm">
                {{ errors.first("cosmeticInjectorExperienceYears") }}
              </span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
              >
                Please list any courses in cosmetic injectables that you have
                completed. (Optional)
              </label>
              <vs-textarea
                v-model="nurseData.cosmeticInjectableCourses"
                v-validate="'max:255'"
                name="cosmeticInjectableCourses"
                data-vv-validate-on="blur"
                data-vv-as="nurse injectable courses"
              />
              <span class="text-danger text-sm">
                {{ errors.first("cosmeticInjectableCourses") }}
              </span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
            id="trainingCertificateUpload"
            class="vs-con-loading__container"
          >
            <div class="w-full m-5">
              <div
                :class="{ dateClass: !errors.has('trainingCertificate') }"
                style=""
              >
                <label class="vs-input--label custom-label"
                  >Please upload any training completion certificates
                  (Optional)</label
                >
                <div style="margin-top: 10px; margin-bottom: 10px">
                  <div class="mb-4" v-if="nurseData.trainingCertificate">
                    <div
                      v-if="
                        determineFileType(nurseData.trainingCertificate) ===
                        'pdf'
                      "
                      style="margin: auto; width: 50%"
                    >
                      <a :href="nurseData.trainingCertificate" target="_blank"
                        >Click here to view file</a
                      >
                    </div>
                    <div
                      v-else
                      style="margin: auto"
                      class="register-img-wrapper"
                    >
                      <img
                        :src="nurseData.trainingCertificate"
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <div
                    :class="{ 'mt-3': nurseData.trainingCertificate }"
                    style="text-align: center; position: relative"
                  >
                    <label
                      id="trainingCertificate"
                      :class="{
                        'six-pixel': errors.has('trainingCertificate'),
                      }"
                      class="image-upload training-certificate-upload"
                      style="
                        border: 1px solid #cccccc;
                        display: inline-block;
                        width: 100%;
                        border-radius: 5px;
                        padding: 10px;
                      "
                    >
                      + Attach Document
                      <input
                        type="file"
                        @change="
                          uploadImage($event, 'trainingCertificateUpload')
                        "
                        id="file-input"
                        hidden
                      />
                    </label>
                  </div>
                </div>
              </div>
              <span class="text-danger text-sm">
                {{ errors.first("trainingCertificate") }}
              </span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
              >
                Please list any other teaching that you have had in injectables.
                (For instance, from another nurse? A Doctor? On-the-job
                training? From a chain clinic? Please provide some detail
                (names, locations, durations))
              </label>
              <vs-textarea
                style="margin-bottom: 10px !important"
                v-model="nurseData.additionalTeachingInInjectables"
                v-validate="{ required: isRequired, max: 255 }"
                name="additionalTeachingInInjectables"
                data-vv-validate-on="blur"
                data-vv-as="additional injectable teachings"
              />
              <span class="text-danger text-sm">
                {{ errors.first("additionalTeachingInInjectables") }}
              </span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
                >On average, how many hours of in-clinic cosmetic practice per
                week do you currently do?</label
              >
              <vs-input
                :danger="
                  errors.first('averageHourInCosmeticPracticePerWeek')
                    ? true
                    : false
                "
                :success="
                  !errors.first('averageHourInCosmeticPracticePerWeek') &&
                  nurseData.averageHourInCosmeticPracticePerWeek != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="{ required: isRequired, decimal: 2 }"
                data-vv-validate-on="blur"
                data-vv-as="average hour in cosmetic practice per week"
                label-placeholder=""
                name="averageHourInCosmeticPracticePerWeek"
                placeholder=""
                v-model="nurseData.averageHourInCosmeticPracticePerWeek"
                class="w-full"
              />
              <span class="text-danger text-sm">
                {{ errors.first("averageHourInCosmeticPracticePerWeek") }}
              </span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
                >How many hours per week of in-clinic practice do you plan to
                do?</label
              >
              <vs-input
                :danger="
                  errors.first('planForAverageHourInCosmeticPracticePerWeek')
                    ? true
                    : false
                "
                :success="
                  !errors.first(
                    'planForAverageHourInCosmeticPracticePerWeek'
                  ) &&
                  nurseData.planForAverageHourInCosmeticPracticePerWeek != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="{ required: isRequired, decimal: 2 }"
                data-vv-validate-on="blur"
                data-vv-as="plan for desired hours in cosmetic practice per week"
                label-placeholder=""
                name="planForAverageHourInCosmeticPracticePerWeek"
                placeholder=""
                v-model="nurseData.planForAverageHourInCosmeticPracticePerWeek"
                class="w-full"
              />
              <span class="text-danger text-sm">
                {{
                  errors.first("planForAverageHourInCosmeticPracticePerWeek")
                }}
              </span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
                >Roughly, how many patients do you think you have
                treated?</label
              >
              <vs-input
                :danger="errors.first('numberOfPatientsTreated') ? true : false"
                :success="
                  !errors.first('numberOfPatientsTreated') &&
                  nurseData.numberOfPatientsTreated != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="{ required: isRequired, integer }"
                data-vv-validate-on="blur"
                data-vv-as="number of patients treated"
                label-placeholder=""
                name="numberOfPatientsTreated"
                placeholder=""
                v-model="nurseData.numberOfPatientsTreated"
                class="w-full"
              />
              <span class="text-danger text-sm">
                {{ errors.first("numberOfPatientsTreated") }}
              </span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <vs-row>
                <label
                  style="display: block"
                  class="vs-input--label custom-label mb-1"
                >
                  What are your confident areas for toxin treatment?
                </label>

                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="nurseData.confidentAreasForToxinTreatment.Glabella"
                    @change="validationKeys.showToxinTreatmentError = false"
                    >Glabella</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="
                      nurseData.confidentAreasForToxinTreatment.Frontalis
                    "
                    @change="validationKeys.showToxinTreatmentError = false"
                    >Frontalis</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="
                      nurseData.confidentAreasForToxinTreatment[
                        'Lateral Orbicularis Oculi'
                      ]
                    "
                    @change="validationKeys.showToxinTreatmentError = false"
                    >Lateral Orbicularis Oculi</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="
                      nurseData.confidentAreasForToxinTreatment['Lip eversion']
                    "
                    @change="validationKeys.showToxinTreatmentError = false"
                    >Lip eversion</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="
                      nurseData.confidentAreasForToxinTreatment[
                        'Lipstick lines'
                      ]
                    "
                    @change="validationKeys.showToxinTreatmentError = false"
                    >Lipstick lines</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  v-if="showComplianceFields"
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="
                      nurseData.confidentAreasForToxinTreatment['Brow Lift']
                    "
                    @change="validationKeys.showToxinTreatmentError = false"
                    >Brow Lift</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="
                      nurseData.confidentAreasForToxinTreatment[
                        'Nasalis (bunny lines)'
                      ]
                    "
                    @change="validationKeys.showToxinTreatmentError = false"
                    >Nasalis (bunny lines)</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="
                      nurseData.confidentAreasForToxinTreatment[
                        'LLSAN (gummy smile)'
                      ]
                    "
                    @change="validationKeys.showToxinTreatmentError = false"
                    >LLSAN (gummy smile)</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="nurseData.confidentAreasForToxinTreatment.Mentalis"
                    @change="validationKeys.showToxinTreatmentError = false"
                    >Mentalis</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="nurseData.confidentAreasForToxinTreatment.DAO"
                    @change="validationKeys.showToxinTreatmentError = false"
                    >DAO</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="
                      nurseData.confidentAreasForToxinTreatment.Masseters
                    "
                    @change="validationKeys.showToxinTreatmentError = false"
                    >Masseters</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="
                      nurseData.confidentAreasForToxinTreatment.Hyperhidrosis
                    "
                    @change="validationKeys.showToxinTreatmentError = false"
                    >Hyperhidrosis</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="nurseData.confidentAreasForToxinTreatment.Platysma"
                    @change="validationKeys.showToxinTreatmentError = false"
                    >Platysma</vs-checkbox
                  >
                </vs-col>
              </vs-row>
              <span
                v-if="validationKeys.showToxinTreatmentError"
                style="display: block"
                class="text-danger text-sm"
                >Please select at least one option.</span
              >
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <vs-row>
                <label
                  style="display: block"
                  class="vs-input--label custom-label mb-1"
                >
                  What are your confident areas for filler treatment?
                </label>

                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="
                      nurseData.confidentAreasForFillerTreatment['Lip filler']
                    "
                    @change="validationKeys.showFillerTreatmentError = false"
                    >Lip filler</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="nurseData.confidentAreasForFillerTreatment.Chin"
                    @change="validationKeys.showFillerTreatmentError = false"
                    >Chin</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="
                      nurseData.confidentAreasForFillerTreatment['Tear Trough']
                    "
                    @change="validationKeys.showFillerTreatmentError = false"
                    >Tear Trough</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="
                      nurseData.confidentAreasForFillerTreatment['Cheeks']
                    "
                    @change="validationKeys.showFillerTreatmentError = false"
                    >Cheeks</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="
                      nurseData.confidentAreasForFillerTreatment['Jawline']
                    "
                    @change="validationKeys.showFillerTreatmentError = false"
                    >Jawline</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="
                      nurseData.confidentAreasForFillerTreatment[
                        'Lip-stick lines'
                      ]
                    "
                    @change="validationKeys.showFillerTreatmentError = false"
                    >Lip-stick lines</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="nurseData.confidentAreasForFillerTreatment['NLF']"
                    @change="validationKeys.showFillerTreatmentError = false"
                    >NLF</vs-checkbox
                  >
                </vs-col>
                <vs-col
                  class="mb-2"
                  vs-type="flex"
                  vs-justify="left"
                  vs-align="left"
                  vs-w="12"
                >
                  <vs-checkbox
                    v-model="
                      nurseData.confidentAreasForFillerTreatment[
                        'Marionette Lines'
                      ]
                    "
                    @change="validationKeys.showFillerTreatmentError = false"
                    >Marionette Lines</vs-checkbox
                  >
                </vs-col>
              </vs-row>
              <span
                v-if="validationKeys.showFillerTreatmentError"
                style="display: block"
                class="text-danger text-sm"
                >Please select at least one option.</span
              >
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
              >
                Are there any areas that you are not confident in?
              </label>
              <vs-textarea
                style="margin-bottom: 10px !important"
                v-model="nurseData.areasNotConfidentIn"
                v-validate="{ required: isRequired, max: 255 }"
                name="areasNotConfidentIn"
                data-vv-validate-on="blur"
                data-vv-as="areas not confident in"
              />
              <span class="text-danger text-sm">
                {{ errors.first("areasNotConfidentIn") }}
              </span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
              >
                Are there any other areas, products or techniques are part of
                your practice?
              </label>
              <vs-textarea
                style="margin-bottom: 10px !important"
                v-model="nurseData.additionalAreaOfPractice"
                v-validate="{ required: isRequired, max: 255 }"
                name="additionalAreaOfPractice"
                data-vv-validate-on="blur"
                data-vv-as="additional areas, products or techniques of practice"
              />
              <span class="text-danger text-sm">
                {{ errors.first("additionalAreaOfPractice") }}
              </span>
            </div>
          </vs-col>

          <!--          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
            id="cvUpload"
            class="vs-con-loading__container"
          >
            <div class="w-full m-5">
              <div :class="{ dateClass: !errors.has('cv') }">
                <label class="vs-input&#45;&#45;label custom-label"
                  >CV (Please upload a CV with at least two references)</label
                >
                <div style="margin-top:10px; margin-bottom:10px;">
                  <div class="mb-4" v-if="nurseData.cv">
                    <div
                      v-if="determineFileType(nurseData.cv) === 'pdf'"
                      style="margin:auto;width:50%;"
                    >
                      <a :href="nurseData.cv" target="_blank"
                      >Click here to view file</a
                      >
                    </div>
                    <div
                      v-else
                      style="margin:auto;"
                      class="register-img-wrapper"
                    >
                      <img :src="nurseData.cv" class="img-fluid" />
                    </div>
                  </div>
                  <div
                    :class="{ 'mt-3': nurseData.cv }"
                    style="text-align:center;position:relative;"
                  >
                    <label
                      id="cvId"
                      :class="{ 'six-pixel': errors.has('cv') }"
                      class="image-upload cv-upload"
                      style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
                    >
                      + Attach Document
                      <input
                        type="file"
                        @change="uploadImage($event, 'cvUpload')"
                        id="file-input"
                        hidden
                      />
                    </label>
                  </div>
                </div>
              </div>
              <span class="text-danger text-sm">
                {{ errors.first("cv") }}
              </span>
            </div>
          </vs-col>-->

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
                >Have you completed hyalase training in the last 12
                months?</label
              >
              <vs-radio
                vs-value="Yes"
                vs-name="radios5"
                style="margin-right: 20px"
                v-model="nurseData.hasCompletedHyalaseTraningInLastTwelveMonth"
                @change="
                  validationKeys.showHasCompletedHyalaseTraningInLastTwelveMonth = false
                "
                >Yes</vs-radio
              >
              <vs-radio
                vs-value="No"
                vs-name="radios5"
                v-model="nurseData.hasCompletedHyalaseTraningInLastTwelveMonth"
                @change="
                  validationKeys.showHasCompletedHyalaseTraningInLastTwelveMonth = false
                "
                >No</vs-radio
              >
              <span
                v-if="
                  validationKeys.showHasCompletedHyalaseTraningInLastTwelveMonth
                "
                style="display: block"
                class="text-danger text-sm"
                >Please select one option.</span
              >
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
            id="hyalaseManagenentCertificateUpload"
            class="vs-con-loading__container"
          >
            <div class="w-full m-5">
              <div
                style=""
                :class="{
                  dateClass: !errors.has(
                    'hyalaseOrComplicationManagementCertificate'
                  ),
                }"
              >
                <label
                  style="display: block"
                  class="vs-input--label custom-label mb-1"
                  >Please upload any relevant certificates for
                  hyalase/complication management (If you haven't done a course
                  we recommend
                  https://asi.braincert.com/lms/course/24241-AFI-1-0-Vascular-Complications-Emergencies#)</label
                >
                <div style="margin-top: 10px; margin-bottom: 10px">
                  <div
                    class="mb-4"
                    v-if="nurseData.hyalaseOrComplicationManagementCertificate"
                  >
                    <div
                      v-if="
                        determineFileType(
                          nurseData.hyalaseOrComplicationManagementCertificate
                        ) === 'pdf'
                      "
                      style="margin: auto; width: 50%"
                    >
                      <a
                        :href="
                          nurseData.hyalaseOrComplicationManagementCertificate
                        "
                        target="_blank"
                        >Click here to view file</a
                      >
                    </div>
                    <div
                      v-else
                      style="margin: auto"
                      class="register-img-wrapper"
                    >
                      <img
                        :src="
                          nurseData.hyalaseOrComplicationManagementCertificate
                        "
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <div
                    :class="{
                      'mt-3':
                        nurseData.hyalaseOrComplicationManagementCertificate,
                    }"
                    style="text-align: center; position: relative"
                  >
                    <label
                      id="hyalaseManagenentCertificateId"
                      :class="{
                        'six-pixel': errors.has(
                          'hyalaseOrComplicationManagementCertificate'
                        ),
                      }"
                      class="image-upload hyalase-managenent-certificate-upload"
                      style="
                        border: 1px solid #cccccc;
                        display: inline-block;
                        width: 100%;
                        border-radius: 5px;
                        padding: 10px;
                      "
                    >
                      + Attach Document
                      <input
                        type="file"
                        @change="
                          uploadImage(
                            $event,
                            'hyalaseManagenentCertificateUpload'
                          )
                        "
                        id="file-input"
                        hidden
                      />
                    </label>
                  </div>
                </div>
              </div>
              <span class="text-danger text-sm">
                {{ errors.first("hyalaseOrComplicationManagementCertificate") }}
              </span>
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                >Hyalase Training Expiration Date (When does your training
                expire? (1 year maximum))</label
              >
              <datepicker
                :disabled-dates="disabledDates"
                format="dd/MM/yyyy"
                v-model="nurseData.hyalaseTrainingExpirationDate"
                :input-class="{
                  'is-true':
                    !errors.has('hyalaseTrainingExpirationDate') &&
                    nurseData.hyalaseTrainingExpirationDate,
                  'is-danger': errors.has('hyalaseTrainingExpirationDate'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="nurseData.hyalaseTrainingExpirationDate"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('hyalaseTrainingExpirationDate')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger" style="font-size: 0.75em">
                {{ errors.first("hyalaseTrainingExpirationDate") }}
              </span>
              <input
                type="hidden"
                data-vv-as="Hyalase Training Expiration Date"
                data-vv-validate-on="change"
                name="hyalaseTrainingExpirationDate"
                v-validate="{ required: isRequired }"
                v-model="nurseData.hyalaseTrainingExpirationDate"
              />
            </div>
          </vs-col>

          <!--          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label
                style="display: block;"
                class="vs-input&#45;&#45;label custom-label mb-1"
                >Have you completed Basic Life Support training (BLS) in the
                last 12 months?</label
              >
              <vs-radio
                vs-value="Yes"
                vs-name="radios6"
                style="margin-right: 20px;"
                v-model="
                  nurseData.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth
                "
                @change="
                  validationKeys.showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth = false
                "
              >Yes</vs-radio
              >
              <vs-radio
                vs-value="No"
                vs-name="radios6"
                v-model="
                  nurseData.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth
                "
                @change="
                  validationKeys.showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth = false
                "
              >No</vs-radio
              >
              <span
                v-if="
                  validationKeys.showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth
                "
                style="display: block;"
                class="text-danger text-sm"
              >Please select one option.</span
              >
            </div>
          </vs-col>-->

          <!--
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
            id="blsCertificateUpload"
            class="vs-con-loading__container"
          >
            <div class="w-full m-5">
              <div
                style=""
                :class="{ dateClass: !errors.has('blsCertificate') }"
              >
                <label
                  style="display: block;"
                  class="vs-input&#45;&#45;label custom-label mb-1"
                >Please upload your BLS certificate</label
                >
                <div style="margin-top:10px; margin-bottom:10px;">
                  <div class="mb-4" v-if="nurseData.blsCertificate">
                    <div
                      v-if="
                        determineFileType(nurseData.blsCertificate) === 'pdf'
                      "
                      style="margin:auto;width:50%;"
                    >
                      <a :href="nurseData.blsCertificate" target="_blank"
                      >Click here to view file</a
                      >
                    </div>
                    <div
                      v-else
                      style="margin:auto;"
                      class="register-img-wrapper"
                    >
                      <img :src="nurseData.blsCertificate" class="img-fluid" />
                    </div>
                  </div>
                  <div
                    :class="{ 'mt-3': nurseData.blsCertificate }"
                    style="text-align:center;position:relative;"
                  >
                    <label
                      id="blsCertificateId"
                      :class="{ 'six-pixel': errors.has('blsCertificate') }"
                      class="image-upload bls-certificate-upload"
                      style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
                    >
                      + Attach Document
                      <input
                        type="file"
                        @change="uploadImage($event, 'blsCertificateUpload')"
                        id="file-input"
                        hidden
                      />
                    </label>
                  </div>
                </div>
              </div>
              <span class="text-danger text-sm">
                {{ errors.first("blsCertificate") }}
              </span>
            </div>
          </vs-col>
-->

          <!--          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label
              >BLS Training Expiration Date (When does your training expire?
                (1 year maximum))</label
              >
              <datepicker
                :disabled-dates="disabledDates"
                format="dd/MM/yyyy"
                v-model="nurseData.blsTrainingExpirationDate"
                :input-class="{
                  'is-true':
                    !errors.has('blsTrainingExpirationDate') &&
                    nurseData.blsTrainingExpirationDate,
                  'is-danger': errors.has('blsTrainingExpirationDate')
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="nurseData.blsTrainingExpirationDate"
                    class="bg-color-done input-icon-validate vs-input&#45;&#45;icon-validate"
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                    >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('blsTrainingExpirationDate')"
                    class="bg-color-error input-icon-validate vs-input&#45;&#45;icon-validate"
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                    >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger" style="font-size: 0.75em">
                {{ errors.first("blsTrainingExpirationDate") }}
              </span>
              <input
                type="hidden"
                data-vv-as="BLS Training Expiration Date"
                data-vv-validate-on="change"
                name="blsTrainingExpirationDate"
                v-validate="{ required: isRequired }"
                v-model="nurseData.blsTrainingExpirationDate"
              />
            </div>
          </vs-col>-->

          <!--          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label
                style="display: block;"
                class="vs-input&#45;&#45;label custom-label mb-1"
              >Has immunity to Hepatitis B</label
              >
              <vs-radio
                vs-value="Yes"
                vs-name="radios15"
                style="margin-right: 20px;"
                v-model="nurseData.hasImmunityToHepatitisB"
                @change="
                  validationKeys.showHasImmunityToHepatitisBError = false
                "
              >Yes</vs-radio
              >
              <vs-radio
                vs-value="No"
                vs-name="radios15"
                v-model="nurseData.hasImmunityToHepatitisB"
                @change="
                  validationKeys.showHasImmunityToHepatitisBError = false
                "
              >No</vs-radio
              >
              <span
                v-if="validationKeys.showHasImmunityToHepatitisBError"
                style="display: block;"
                class="text-danger text-sm"
              >Please select one option.</span
              >
            </div>
          </vs-col>-->

          <!--          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
            id="vaccinationCertificateUpload"
            class="vs-con-loading__container"
          >
            <div class="w-full m-5">
              <div
                style=""
                :class="{ dateClass: !errors.has('vaccinationCertificate') }"
              >
                <label
                  style="display: block;"
                  class="vs-input&#45;&#45;label custom-label mb-1"
                >Please upload your vaccination certification (We need to see
                  evidence of Hepatitis B immunity or vaccination)</label
                >
                <div style="margin-top:10px; margin-bottom:10px;">
                  <div class="mb-4" v-if="nurseData.vaccinationCertificate">
                    <div
                      v-if="
                        determineFileType(nurseData.vaccinationCertificate) ===
                          'pdf'
                      "
                      style="margin:auto;width:50%;"
                    >
                      <a
                        :href="nurseData.vaccinationCertificate"
                        target="_blank"
                      >Click here to view file</a
                      >
                    </div>
                    <div
                      v-else
                      style="margin:auto;"
                      class="register-img-wrapper"
                    >
                      <img
                        :src="nurseData.vaccinationCertificate"
                        class="img-fluid"
                      />
                    </div>
                  </div>
                  <div
                    :class="{ 'mt-3': nurseData.vaccinationCertificate }"
                    style="text-align:center;position:relative;"
                  >
                    <label
                      id="vaccinationCertificateId"
                      :class="{
                        'six-pixel': errors.has('vaccinationCertificate')
                      }"
                      class="image-upload vaccination-certificate-upload"
                      style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
                    >
                      + Attach Document
                      <input
                        type="file"
                        @change="
                          uploadImage($event, 'vaccinationCertificateUpload')
                        "
                        id="file-input"
                        hidden
                      />
                    </label>
                  </div>
                </div>
              </div>
              <span class="text-danger text-sm">
                {{ errors.first("vaccinationCertificate") }}
              </span>
            </div>
          </vs-col>-->

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
                >Have you read and agree to adhere to the Fresh Clinics
                "Clinical Standards" document?</label
              >
              <vs-radio
                vs-value="Yes"
                vs-name="radios7"
                style="margin-right: 20px"
                v-model="nurseData.hasReadClinicalStandardsDocuments"
                @change="
                  validationKeys.showHasReadClinicalStandardsDocumentsError = false
                "
                >Yes</vs-radio
              >
              <vs-radio
                vs-value="No"
                vs-name="radios7"
                v-model="nurseData.hasReadClinicalStandardsDocuments"
                @change="
                  validationKeys.showHasReadClinicalStandardsDocumentsError = false
                "
                >No</vs-radio
              >
              <span
                v-if="validationKeys.showHasReadClinicalStandardsDocumentsError"
                style="display: block"
                class="text-danger text-sm"
                >Please select one option.</span
              >
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
                >I confirm that I have made a true and accurate
                declaration.</label
              >
              <vs-radio
                vs-value="Yes"
                vs-name="radios8"
                style="margin-right: 20px"
                v-model="nurseData.hasConfirmedDeclarations"
                @change="
                  validationKeys.showHasConfirmedDeclarationsError = false
                "
                >Yes</vs-radio
              >
              <vs-radio
                vs-value="No"
                vs-name="radios8"
                v-model="nurseData.hasConfirmedDeclarations"
                @change="
                  validationKeys.showHasConfirmedDeclarationsError = false
                "
                >No</vs-radio
              >
              <span
                v-if="validationKeys.showHasConfirmedDeclarationsError"
                style="display: block"
                class="text-danger text-sm"
                >Please select one option.</span
              >
            </div>
          </vs-col>

          <vs-col
            v-if="showComplianceFields"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
          >
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
                >Are you happy for us to email you?</label
              >
              <vs-radio
                vs-value="Yes"
                vs-name="radios9"
                style="margin-right: 20px"
                v-model="nurseData.canSendEmail"
                @change="validationKeys.showCanSendEmailError = false"
                >Yes</vs-radio
              >
              <vs-radio
                vs-value="No"
                vs-name="radios9"
                v-model="nurseData.canSendEmail"
                @change="validationKeys.showCanSendEmailError = false"
                >No</vs-radio
              >
              <span
                v-if="validationKeys.showCanSendEmailError"
                style="display: block"
                class="text-danger text-sm"
                >Please select one option.</span
              >
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
                >Is nurse a contractor?</label
              >
              <vs-radio
                vs-value="Yes"
                vs-name="radios3"
                style="margin-right: 20px"
                v-model="nurseData.isContractor"
                @change="validationKeys.isContractorError = false"
                >Yes</vs-radio
              >
              <vs-radio
                vs-value="No"
                vs-name="radios3"
                v-model="nurseData.isContractor"
                @change="validationKeys.isContractorError = false"
                >No</vs-radio
              >
              <span
                v-if="validationKeys.isContractorError"
                style="display: block"
                class="text-danger text-sm"
                >Please select one option.</span
              >
            </div>
          </vs-col>

          <vs-col
            v-if="
              $store.state.AppActiveUser.userType === 'franchise' ||
              $store.state.AppActiveUser.userType === 'subFranchise'
            "
            vs-justify="left"
            vs-align="left"
            vs-w="6"
            style="width: 47% !important"
          >
            <div class="w-full m-5 mb-1">
              <vs-col
                vs-type="flex"
                vs-justify="left"
                vs-align="left"
                vs-w="12"
              >
                <label for="selectClinis">Select Clinics</label>
              </vs-col>
              <v-select
                id="selectClinis"
                multiple
                :closeOnSelect="false"
                label="text"
                danger-text="This field is invalid"
                :options="clinicOptions"
                v-model="associateClinics"
                data-vv-as="applicableAreas"
                data-vv-scope="basic"
                name="applicableAreas"
                class="w-full"
              >
              </v-select>
            </div>
            <span
              v-if="showAssociateClinicError"
              class="text-danger text-sm"
              style="
                display: block;
                margin-left: 18px;
                font-size: 0.75em !important;
              "
              >Please select atleast one clinic.</span
            >
          </vs-col>

          <vs-row>
            <vs-col vs-type="flex" vs-align="left" vs-w="12">
              <div class="w-full m-5" align="right">
                <vs-button class="mr-3" @click="saveData" icon-pack="feather"
                  >Create Nurse</vs-button
                >
                <vs-button
                  color="danger"
                  @click="cancelHandler"
                  icon-pack="feather"
                  >Cancel</vs-button
                >
              </div>
            </vs-col>
          </vs-row>
        </vs-row>
      </vs-form>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "@/axios";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";
import vSelect from "vue-select";
import moment from "moment";
const dict = {
  custom: {
    firstName: {
      required: "Please enter your first name",
    },
    lastName: {
      required: "Please enter your last name",
    },
    email: {
      required: "Please enter valid email address",
    },
    contactNumber: {
      required: "Please enter your contact number",
    },
    ahpraRegistrationNumber: {
      required: "Please enter valid AHPRA registration number",
    },
    ahpraExiryDate: {
      required: "Please enter valid AHPRA Expiry Date",
    },
    ahpraCertificate: {
      required: "Please upload your AHPRA certificate file",
    },
    indemnityInsuranceProvider: {
      required: "Please enter valid indemnity insurance name",
    },
    indemnityInsuranceNumber: {
      required: "Please enter valid indemnity insurance number",
    },
    indemnityInsuranceExpiry: {
      required: "Please enter valid indemnity insurance expiry date",
    },
    insuranceCertificate: {
      required: "Please upload your insurance certificate file",
    },
    password: {
      required: "Please enter your password",
      min: "Password must be at least 8 characters",
      regex: "Must have at least one number and  one uppercase letter",
    },
    selectClinic: {
      required: "Please select the associated clinic/s",
    },
  },
};
Validator.localize("en", dict);
export default {
  name: "create-doctor",
  props: {
    fetchClinicsBy: {
      type: String,
      default: "franchise",
    },
    franchiseId: {
      type: String,
      default: "",
    },
  },
  components: {
    "v-select": vSelect,
    Datepicker,
  },
  data: () => ({
    showAssociateClinicError: false,
    associateClinics: [],
    clinicOptions: [],
    showComplianceFields: true,
    isRequired: true,
    disabledDates: { to: new Date() },
    clinics: [],
    clinic: "",
    validationKeys: {
      showRestrictionImposeError: false,
      showAggreementOnAhpraRegistrationChangedError: false,
      showAggreementOnIndemnityRegistrationChangedError: false,
      showRnOrEnError: false,
      showToxinTreatmentError: false,
      showFillerTreatmentError: false,
      showHasCompletedHyalaseTraningInLastTwelveMonth: false,
      // showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth: false,
      showHasReadClinicalStandardsDocumentsError: false,
      // showHasImmunityToHepatitisBError: false,
      showHasConfirmedDeclarationsError: false,
      showCanSendEmailError: false,
    },
    nurseData: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      preferredName: "",
      contactNumber: "",
      ahpraRegistrationNumber: "",
      ahpraExiryDate: "",
      indemnityInsuranceProvider: "",
      indemnityInsuranceNumber: "",
      indemnityInsuranceExpiry: "",
      profileImage: "",
      ahpraCertificate: "",
      insuranceCertificate: "",
      signature: "",
      userType: "nurse",
      status: "Active",
      associatedClinicIds: "",
      postalAddress: "",
      clinicName: null,
      instagram: null,
      facebook: null,
      website: null,
      clinicAddress1: null,
      city: null,
      stateOrRegion: null,
      photoId: null,
      restrictionImposed: null,
      aggreementOnAhpraRegistrationChanged: null,
      aggreementOnIndemnityRegistrationChanged: null,
      rnOrEn: null,
      expericedYearInRnOrEn: null,
      areasForRnOrEn: null,
      cosmeticInjectorExperienceYears: null,
      cosmeticInjectableCourses: null,
      trainingCertificate: null,
      additionalTeachingInInjectables: null,
      averageHourInCosmeticPracticePerWeek: null,
      planForAverageHourInCosmeticPracticePerWeek: null,
      numberOfPatientsTreated: null,
      confidentAreasForToxinTreatment: {
        Glabella: false,
        Frontalis: false,
        "Lateral Orbicularis Oculi": false,
        "Lip eversion": false,
        "Lipstick lines": false,
        "Brow Lift": false,
        "Nasalis (bunny lines)": false,
        "LLSAN (gummy smile)": false,
        Mentalis: false,
        DAO: false,
        Masseters: false,
        Hyperhidrosis: false,
        Platysma: false,
      },
      confidentAreasForFillerTreatment: {
        "Lip filler": false,
        Chin: false,
        "Tear Trough": false,
        Cheeks: false,
        Jawline: false,
        "Lip-stick lines": false,
        NLF: false,
        "Marionette Lines": false,
      },
      areasNotConfidentIn: null,
      additionalAreaOfPractice: null,
      // cv: null,
      hasCompletedHyalaseTraningInLastTwelveMonth: null,
      hyalaseOrComplicationManagementCertificate: null,
      /*hasCompletedBasicLifeSupportTrainingInLastTwelveMonth: null,
      blsCertificate: null,
      vaccinationCertificate: null,
      hasImmunityToHepatitisB: null,*/
      hasReadClinicalStandardsDocuments: null,
      hasConfirmedDeclarations: null,
      canSendEmail: null,
      dateOfBirth: "",
      hyalaseTrainingExpirationDate: "",
      isContractor: false,
      // blsTrainingExpirationDate: ""
    },
    page: 1,
    limit: 10,
    searchText: "",
    items: [],
    passwordType: "password",
    passwordIcon: "icon icon-eye-off",
  }),
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.nurseData.firstName != "" &&
        this.nurseData.lastName != "" &&
        this.nurseData.contactNumber != "" &&
        this.nurseData.ahpraRegistrationNumber != "" &&
        this.nurseData.ahpraExiryDate != "" &&
        this.nurseData.indemnityInsuranceProvider != "" &&
        this.nurseData.indemnityInsuranceNumber != "" &&
        this.nurseData.indemnityInsuranceExpiry != "" &&
        this.nurseData.ahpraCertificate != "" &&
        this.nurseData.insuranceCertificate != "" &&
        this.nurseData.clinicName != null &&
        this.nurseData.city != null &&
        this.nurseData.stateOrRegion != null &&
        this.nurseData.restrictionImposed != null &&
        this.nurseData.aggreementOnAhpraRegistrationChanged != null &&
        this.nurseData.aggreementOnIndemnityRegistrationChanged != null &&
        this.nurseData.rnOrEn != null &&
        this.nurseData.expericedYearInRnOrEn != null &&
        this.nurseData.areasForRnOrEn != null &&
        this.nurseData.cosmeticInjectorExperienceYears != null &&
        this.nurseData.additionalTeachingInInjectables != null &&
        this.nurseData.averageHourInCosmeticPracticePerWeek != null &&
        this.nurseData.planForAverageHourInCosmeticPracticePerWeek != null &&
        this.nurseData.numberOfPatientsTreated != null &&
        this.nurseData.areasNotConfidentIn != null &&
        this.nurseData.additionalAreaOfPractice != null &&
        // this.nurseData.cv != null &&
        this.nurseData.hasCompletedHyalaseTraningInLastTwelveMonth != null &&
        this.nurseData.hyalaseOrComplicationManagementCertificate != null &&
        /*this.nurseData.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth !=
        null &&
        this.nurseData.blsCertificate != null &&
        this.nurseData.vaccinationCertificate != null &&
        this.nurseData.hasImmunityToHepatitisB != null &&*/
        this.nurseData.hasReadClinicalStandardsDocuments != null &&
        this.nurseData.hasConfirmedDeclarations != null &&
        this.nurseData.canSendEmail != null &&
        this.nurseData.dateOfBirth != "" &&
        this.nurseData.isContractor != "" &&
        this.nurseData.hyalaseTrainingExpirationDate != "" /*&&
        this.nurseData.blsTrainingExpirationDate != ""*/
      );
    },
  },
  methods: {
    ...mapActions("franchise", ["registerFranchiseNurse", "fetchSuburbs"]),
    ...mapActions("franchise", ["fetchClinics"]),
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    async getSuburb() {
      var self = this;
      let data = {
        page: this.page,
        limit: this.limit,
      };
      if (this.searchText !== "") {
        data.searchText = this.searchText;
      }
      await this.fetchSuburbs(data).then((res) => {
        self.items = res.data.data.docs;
      });
    },
    async searchSuburb(searchText) {
      this.searchText = searchText;
      await this.getSuburb();
    },
    async saveData() {
      let self = this;
      let user = self.$store.state.AppActiveUser.userRole;
      let isValid = await this.$validator.validate();
      if (this.nurseData.ahpraCertificate == "") {
        isValid = false;
        self.errors.add({
          field: "ahpraCertificate",
          msg: "AHPRA Certificate is required",
        });
        document.getElementById("ahpraId").style.borderColor = "#ea5455";
      } else {
        document.getElementById("ahpraId").style.borderColor = "#cccccc";
        self.errors.remove("ahpraCertificate");
      }
      if (this.nurseData.insuranceCertificate == "") {
        isValid = false;
        self.errors.add({
          field: "insuranceCertificate",
          msg: "Insurance Certificate is required",
        });
        document.getElementById("insuranceId").style.borderColor = "#ea5455";
      } else {
        document.getElementById("insuranceId").style.borderColor = "#cccccc";
        self.errors.remove("insuranceCertificate");
      }
      if (
        moment(new Date(self.nurseData.dateOfBirth)).format("YYYY-MM-DD") >
        moment().subtract(18, "years").format("YYYY-MM-DD")
      ) {
        isValid = false;
        self.errors.add({
          field: "dateOfBirth",
          msg: "Your age must be 18 years or above",
        });
      } else {
        self.nurseData.dateOfBirth = moment(
          new Date(self.nurseData.dateOfBirth)
        ).format("YYYY-MM-DD");
        self.errors.remove("dateOfBirth");
      }

      if (
        this.$store.state.AppActiveUser.userType !== "superAdmin" &&
        this.$store.state.AppActiveUser.userType !== "admin" &&
        this.$store.state.AppActiveUser.userType !== "franchise" &&
        this.$store.state.AppActiveUser.userType !== "subFranchise" &&
        this.$store.state.AppActiveUser.userType !== "clinic" &&
        this.$store.state.AppActiveUser.userType !== "subClinic"
      ) {
        /*if (!this.nurseData.cv) {
          isValid = false;
          self.errors.add({
            field: "cv",
            msg: "CV is required"
          });
          document.getElementById("cvId").style.borderColor = "#ea5455";
        } else {
          document.getElementById("cvId").style.borderColor = "#cccccc";
          self.errors.remove("cv");
        }*/

        if (
          moment(new Date(self.nurseData.hyalaseTrainingExpirationDate)).format(
            "YYYY-MM-DD"
          ) > moment().add(1, "years").format("YYYY-MM-DD")
        ) {
          isValid = false;
          self.errors.add({
            field: "hyalaseTrainingExpirationDate",
            msg: "Hyalase Training Expiration Date must be maximum 1 year",
          });
        } else {
          self.nurseData.hyalaseTrainingExpirationDate = moment(
            new Date(self.nurseData.hyalaseTrainingExpirationDate)
          ).format("YYYY-MM-DD");
          self.errors.remove("hyalaseTrainingExpirationDate");
        }

        /*if (
          moment(new Date(self.nurseData.blsTrainingExpirationDate)).format(
            "YYYY-MM-DD"
          ) >
          moment()
            .add(1, "years")
            .format("YYYY-MM-DD")
        ) {
          isValid = false;
          self.errors.add({
            field: "blsTrainingExpirationDate",
            msg: "BLS Training Expiration Date must be maximum 1 year"
          });
        } else {
          self.nurseData.blsTrainingExpirationDate = moment(
            new Date(self.nurseData.blsTrainingExpirationDate)
          ).format("YYYY-MM-DD");
          self.errors.remove("blsTrainingExpirationDate");
        }*/

        /*if (!self.nurseData.restrictionImposed) {
          isValid = false;
          self.validationKeys.showRestrictionImposeError = true;
        } else {
          self.validationKeys.showRestrictionImposeError = false;
        }*/

        /*if (!self.nurseData.hasImmunityToHepatitisB) {
          isValid = false;
          self.validationKeys.showHasImmunityToHepatitisBError = true;
        } else {
          self.validationKeys.showHasImmunityToHepatitisBError = false;
        }*/

        if (!self.nurseData.canSendEmail) {
          isValid = false;
          self.validationKeys.showCanSendEmailError = true;
        } else {
          self.validationKeys.showCanSendEmailError = false;
        }

        if (!self.nurseData.hasConfirmedDeclarations) {
          isValid = false;
          self.validationKeys.showHasConfirmedDeclarationsError = true;
        } else {
          self.validationKeys.showHasConfirmedDeclarationsError = false;
        }

        if (!self.nurseData.hasReadClinicalStandardsDocuments) {
          isValid = false;
          self.validationKeys.showHasReadClinicalStandardsDocumentsError = true;
        } else {
          self.validationKeys.showHasReadClinicalStandardsDocumentsError = false;
        }

        /*if (
          !self.nurseData.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth
        ) {
          isValid = false;
          self.validationKeys.showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth = true;
        } else {
          self.validationKeys.showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth = false;
        }*/

        if (!self.nurseData.aggreementOnAhpraRegistrationChanged) {
          isValid = false;
          self.validationKeys.showAggreementOnAhpraRegistrationChangedError = true;
        } else {
          self.validationKeys.showAggreementOnAhpraRegistrationChangedError = false;
        }

        if (!self.nurseData.aggreementOnIndemnityRegistrationChanged) {
          isValid = false;
          self.validationKeys.showAggreementOnIndemnityRegistrationChangedError = true;
        } else {
          self.validationKeys.showAggreementOnIndemnityRegistrationChangedError = false;
        }

        if (!self.nurseData.hasCompletedHyalaseTraningInLastTwelveMonth) {
          isValid = false;
          self.validationKeys.showHasCompletedHyalaseTraningInLastTwelveMonth = true;
        } else {
          self.validationKeys.showHasCompletedHyalaseTraningInLastTwelveMonth = false;
        }

        if (!self.nurseData.rnOrEn) {
          isValid = false;
          self.validationKeys.showRnOrEnError = true;
        } else {
          self.validationKeys.showRnOrEnError = false;
        }

        var hasTrueKeys = Object.keys(
          self.nurseData.confidentAreasForToxinTreatment
        ).some((k) => self.nurseData.confidentAreasForToxinTreatment[k]);

        if (!hasTrueKeys) {
          isValid = false;
          self.validationKeys.showToxinTreatmentError = true;
        } else {
          self.validationKeys.showToxinTreatmentError = false;
        }

        let checkFillerTrueKeys = Object.keys(
          self.nurseData.confidentAreasForFillerTreatment
        ).some((k) => self.nurseData.confidentAreasForFillerTreatment[k]);
        if (!checkFillerTrueKeys) {
          isValid = false;
          self.validationKeys.showFillerTreatmentError = true;
        } else {
          self.validationKeys.showFillerTreatmentError = false;
        }

        if (!self.nurseData.hyalaseOrComplicationManagementCertificate) {
          self.errors.add({
            field: "hyalaseOrComplicationManagementCertificate",
            msg: "Hyalase or complication management certificate is required",
          });
          isValid = false;
          document.getElementById(
            "hyalaseManagenentCertificateId"
          ).style.borderColor = "#ea5455";
        } else {
          document.getElementById(
            "hyalaseManagenentCertificateId"
          ).style.borderColor = "#cccccc";
          self.errors.remove("hyalaseOrComplicationManagementCertificate");
        }

        /*if (!self.nurseData.blsCertificate) {
          self.errors.add({
            field: "blsCertificate",
            msg: "BLS certificate is required"
          });
          isValid = false;
          document.getElementById("blsCertificateId").style.borderColor =
            "#ea5455";
        } else {
          document.getElementById("blsCertificateId").style.borderColor =
            "#cccccc";
          self.errors.remove("blsCertificate");
        }
*/
        /*if (!self.nurseData.vaccinationCertificate) {
          self.errors.add({
            field: "vaccinationCertificate",
            msg: "Vaccination certificate is required"
          });
          isValid = false;
          document.getElementById(
            "vaccinationCertificateId"
          ).style.borderColor = "#ea5455";
        } else {
          document.getElementById(
            "vaccinationCertificateId"
          ).style.borderColor = "#cccccc";
          self.errors.remove("vaccinationCertificate");
        }*/
      }

      if (
        ["franchise", "subFranchise"].includes(
          this.$store.state.AppActiveUser.userType
        ) &&
        this.associateClinics.length === 0
      ) {
        isValid = false;
        this.showAssociateClinicError = true;
      } else {
        this.showAssociateClinicError = false;
        this.nurseData.choseClinics = this.associateClinics;
      }

      if (isValid) {
        let payload = {};
        this.nurseData.emailVerifiedAt = new Date().toISOString();
        payload.userDetails = this.nurseData;

        if (
          this.$store.state.AppActiveUser.userType === "superAdmin" ||
          this.$store.state.AppActiveUser.userType === "admin"
        ) {
          payload.userDetails.hasApprovedByAdmin = true;
        }
        if (this.franchiseId && this.franchiseId !== "") {
          payload.userDetails.franchiseId = this.franchiseId;
        }

        if (payload.isContractor == "Yes") {
          payload.userDetails.isContractor = true;
        } else {
          payload.userDetails.isContractor = false;
        }

        await this.registerFranchiseNurse(payload)
          .then((res) => {
            self.$vs.notify({
              title: "Nurse Created",
              text: "Nurse created successfully.",
              color: "success",
            });
            if (user === "superAdmin") {
              self.$router.push("/super-admin/nurses");
            } else if (user === "subFranchise") {
              self.$router.push("/franchise/nurses");
            } else if (user === "subClinic") {
              self.$router.push("/clinic/nurses");
            } else {
              self.$router.push("/" + user + "/nurses");
            }
          })
          .catch((err) => {
            isValid = false;
            if (err.status === 422) {
              self.mapServerErrors(self, err);
              self.$vs.notify({
                title: "Failed",
                text: "Failed to create nurse. Try again!",
                color: "danger",
              });
            }
            if (err.status === 409) {
              self.$vs.notify({
                title: "Failed",
                text: err.data.message,
                color: "danger",
              });
              self.errors.add({
                field: "email",
                msg: err.data.message,
              });
            }
          });
      }
    },
    cancel() {
      this.$router.push({ name: "Nurses" });
    },
    chang(key = "") {
      this.$vs.loading({
        text: "Uploading ...",
        color: "blue",
        background: "white",
        container: "#" + key + " div div",
      });
    },
    handleError(key = "") {
      this.$vs.loading.close("#" + key + " div div > .con-vs-loading");
      this.$vs.notify({
        title: "Upload Failed",
        text: "Unable to upload file at the moment.",
        color: "danger",
      });
    },
    successUpload(tab, event) {
      let response = JSON.parse(event.target.response);
      if (tab == "a") {
        this.$vs.loading.close("#aphraUpload div div > .con-vs-loading");
        this.nurseData.ahpraCertificate = response.Location;
        this.errors.remove("aphraCertificate");
      }
      if (tab == "i") {
        this.$vs.loading.close("#insuranceUpload div div > .con-vs-loading");
        this.nurseData.insuranceCertificate = response.Location;
        this.errors.remove("insuranceCertificate");
      }
      if (tab == "pp") {
        this.nurseData.profileImage = response.Location;
        this.$vs.loading.close("#profileUpload div div > .con-vs-loading");
      }

      this.$vs.loading.close();
    },
    deleteFile(tab) {
      if (tab == "a") this.nurseData.ahpraCertificate = "";
      if (tab == "i") this.nurseData.insuranceCertificate = "";
      if (tab == "pp") this.nurseData.profileImage = "";
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key,
        scale: 0.7,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "profileImageUpload") {
            self.nurseData.profileImage = res.data.Location;
          }
          if (key === "aphraUpload") {
            this.errors.remove("ahpraCertificate");
            document.getElementById("ahpraId").style.borderColor = "#cccccc";
            self.nurseData.ahpraCertificate = res.data.Location;
          }
          if (key === "insuranceUpload") {
            this.errors.remove("insuranceCertificate");
            document.getElementById("insuranceId").style.borderColor =
              "#cccccc";
            self.nurseData.insuranceCertificate = res.data.Location;
          }
          if (key === "signatureUpload") {
            this.errors.remove("electronicSignature");
            document.getElementById("signatureId").style.borderColor =
              "#cccccc";
            self.nurseData.signature = res.data.Location;
          }
          if (key === "photoIdUpload") {
            this.errors.remove("photoId");
            document.getElementById("photoId").style.borderColor = "#cccccc";
            self.nurseData.photoId = res.data.Location;
          }
          if (key === "trainingCertificateUpload") {
            this.errors.remove("trainingCertificate");
            document.getElementById("trainingCertificate").style.borderColor =
              "#cccccc";
            self.nurseData.trainingCertificate = res.data.Location;
          }
          if (key === "cvUpload") {
            this.errors.remove("cv");
            document.getElementById("cvId").style.borderColor = "#cccccc";
            self.nurseData.cv = res.data.Location;
          }
          if (key === "hyalaseManagenentCertificateUpload") {
            this.errors.remove("hyalaseOrComplicationManagementCertificate");
            document.getElementById(
              "hyalaseManagenentCertificateId"
            ).style.borderColor = "#cccccc";
            self.nurseData.hyalaseOrComplicationManagementCertificate =
              res.data.Location;
          }
          if (key === "blsCertificateUpload") {
            this.errors.remove("blsCertificate");
            document.getElementById("blsCertificateId").style.borderColor =
              "#cccccc";
            self.nurseData.blsCertificate = res.data.Location;
          }
          if (key === "vaccinationCertificateUpload") {
            this.errors.remove("vaccinationCertificate");
            document.getElementById(
              "vaccinationCertificateId"
            ).style.borderColor = "#cccccc";
            self.nurseData.vaccinationCertificate = res.data.Location;
          }
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              color: "danger",
            });
          }
        });
    },
    determineFileType(str) {
      if (str && str.length > 0) {
        var res = str.substring(str.length - 3, str.length);
        return res;
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
        });
      });
    },
    cancelHandler() {
      const user = this.$store.state.AppActiveUser.userRole;
      if (user === "superAdmin") {
        this.$router.push("/super-admin/nurses");
      }
      if (user === "franchise") {
        this.$router.push("/franchise/nurses");
      }
      if (user === "clinic") {
        this.$router.push("/clinic/nurses");
      }
      if (user === "admin") {
        this.$router.push("/admin/nurses");
      }
    },
  },
  mounted(key = "profileImageUpload") {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.disabledDates.to = d;
  },
  created() {
    let self = this;
    this.fetchClinics({ type: this.fetchClinicsBy }).then((res) => {
      if (self.fetchClinicsBy !== "clinic") {
        this.clinics = res.data.data;

        this.clinicOptions = res.data.data.map((datum) => {
          return {
            text: datum.clinicName || `${datum.firstName} ${datum.lastName}`,
            clinicId: datum._id,
          };
        });
      } else {
        this.clinic = res.data.data.clinicId;
      }
    });

    this.isRequired =
      this.$store.state.AppActiveUser.userType === "superAdmin" ||
      this.$store.state.AppActiveUser.userType === "admin" ||
      this.$store.state.AppActiveUser.userType === "franchise" ||
      this.$store.state.AppActiveUser.userType === "subFranchise"
        ? false
        : true;

    this.showComplianceFields =
      this.$store.state.AppActiveUser.userType === "franchise" ||
      this.$store.state.AppActiveUser.userType === "subFranchise"
        ? false
        : true;
  },
};
</script>
<style lang="scss">
.disabled-upload {
  display: none;
}
.con-img-upload {
  height: 50%;
}
.is-danger {
  border: 1px solid #ea5455 !important;
}
.is-true {
  border: 1px solid #28c76f !important;
}
.bg-color-done {
  background: #d4f4e2;
  color: #28c76f !important;
  border: 1px solid #28c76f !important;
  border-left: hidden !important;
}

.bg-color-error {
  background: #fbdddd;
  color: #ea5455 !important;
  border: 1px solid #ea5455 !important;
  border-left: hidden !important;
}

.image-upload {
  cursor: pointer;
}
.avatar {
  vertical-align: middle;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.style-chooser .vs__search::placeholder {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 0.85rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  letter-spacing: 0.01rem;
}

.custom-calendar > .vdp-datepicker__calendar {
  bottom: 100%;
}
</style>
